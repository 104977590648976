
import React, { Component } from 'react';
import './styles.css';
import { Form, FormGroup, FormControl, InputGroup, Modal, Row, Col } from 'react-bootstrap';
import favicon from '../../assets/images/Sysco_Logo.png';
import { store } from '../..';
import { PORTAL_MESSAGE } from '../../store/actions/actionTypes';
import { connect } from 'react-redux';
import  cookie from 'react-cookies';
import  moment from 'moment';
import { INCORRECT_MESSAGE, UNAUTHORIZED_MESSAGE, NETWORK_ERROR ,INACTIVEVENDOR_MESSAGE, CAPTCHAFAILED_MESSAGE, 
  CAPTCHA_VERIFICATION_REQUIRED_MESSAGE, LOGIN_TYPE, LOGIN_DIRECT} from '../../config/string';
import { auth } from '../../store/actions/loginAction';
import { Button } from 'antd';
import { LoginOutlined, PlayCircleTwoTone, TagsTwoTone, WarningOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";
import ChangePassModalComponent from '../changePasswordModal'
const recaptchaRef = React.createRef();
const searchParams = new URLSearchParams(document.location.search)

class Login extends Component {

  state = {
    username: '',
    password: '',
    errorMsg: '',
    unauth: false,
    serviceFailed: false,
    inactiveVendor:false,
    loading:false,
    captchaFailed: false,
    captchaRequired:false,
    captchaKey: '',
    captchaToken:'',
    changePasswordModal:false,
    passwordExpired:false,
    changeAfterReset:false,
    accountLocked:false,
    showAlertMessage:false
  }

  setTextField = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  }

  componentDidMount() {

    cookie.save(LOGIN_TYPE, LOGIN_DIRECT, {
      path: "/",
    });
    fetch('/get-captcha-key')
      .then(response => response.json())
      .then(data => {
        this.setState({ captchaKey: data.captcha_key });
      })
      .catch(error => {
        console.log(error);
    });
    toast.dismiss()
    store.dispatch({ type: PORTAL_MESSAGE })
    // store.dispatch({ type: LOGIN })
    this.setState({ unauth: false })
    document.getElementById("headerMain").classList.add("loginclass");
    document.getElementById("antTab").classList.add("loginclass");
    let that = this;
    document.getElementById('userId').onkeydown = (e) => {
      if (e.keyCode === 13) {
        that.executeReCaptcha();
      }
      else
        that.setState({ unauth: false })
    };
    document.getElementById('passWord').onkeydown = (e) => {
      if (e.keyCode === 13) {
        that.executeReCaptcha();
      }
      else
        that.setState({ unauth: false })
    };
  }
  componentWillUnmount(){
    document.getElementById("headerMain").classList.remove("loginclass");
    document.getElementById("antTab").classList.remove("loginclass");  
  }
  componentWillReceiveProps = (newProps) => {
    this.setState({loading:false})
    if (newProps.ctr.loginFailed==true) {
      this.setState({ unauth: true });
    }
    if (newProps.ctr.serviceFailed)
      this.setState({ serviceFailed: true });
    if (newProps.ctr.inactiveVendor)
      this.setState({ inactiveVendor: true });
    if (newProps.ctr.captchaFailed)
      this.setState({ captchaFailed: true });
    if (newProps.ctr.passwordExpired)
      this.setState({ changeAfterReset: false, passwordExpired: true, changePasswordModal: true });
    if (newProps.ctr.changeAfterReset)
      this.setState({ passwordExpired: false, changeAfterReset: true, changePasswordModal: true });
    if (newProps.ctr.accountLocked)
      this.setState({ accountLocked: true });
    if ((!newProps.ctr.changeAfterReset) && (!newProps.ctr.passwordExpired))
      this.setState({ changePasswordModal: false });
  }

  getErrorMessage = () => {
    let userName = document.getElementById('userId').value;
    let passWord = document.getElementById('passWord').value;
    let errorMsg = '';
    if (userName === '' && passWord === '') {
      errorMsg = "Username and Password cannot be empty!"
    } else if (userName === '' || passWord === '') {
      if (userName === '') {
        errorMsg = "Username cannot be empty!"
      }
      else {
        errorMsg = "Password cannot be empty!"
      }
    }
    return errorMsg;
  }
  loginAction = () => {
    this.setState({ errorMsg: "" ,loading:true})
    let userName = document.getElementById('userId').value;
    let passWord = document.getElementById('passWord').value;
    const captchaBypassKey = searchParams.get('cpt-key')!=null ? searchParams.get('cpt-key') : "";

      const captchaToken = this.state.captchaToken;
      cookie.save("userName", userName, {
        path: "/",
        // domain: STORAGE_PATH
      });
      localStorage.setItem("vendorNumber",userName)
      let errorMsg = '';
      errorMsg = this.getErrorMessage();
      this.setState({ errorMsg: errorMsg },()=>{
      });
      if (errorMsg.length === 0) {
        this.setState({ unauth: false ,captchaFailed: false ,captchaRequired: false ,captchaToken: ''});
        recaptchaRef.current.reset();
        this.props.OnLogin(userName, passWord, captchaToken,captchaBypassKey);
        // history.push(DASHBOARD);
      }else
      this.setState({loading:false })

  }

  executeReCaptcha = () => {
    let errorMsg  = this.getErrorMessage();
    this.setState({ errorMsg: errorMsg },()=>{});
    if (errorMsg.length === 0) {

      //check cpt-key is avaialble in URL
      if(searchParams.get('cpt-key')!=null && searchParams.get('cpt-key')!=""){
          console.log("cpt-key is found");
          this.loginAction();
          return;
      }
      //check captcha token is available, if cpt-key is null
      if(this.state.captchaToken==''){
        this.setState({captchaRequired:true});
      }else{
        this.loginAction();
      }
    }
  }

  captchaCallBack = (resp) => {
    this.setState({captchaToken:resp,captchaRequired:false});
  }

  handleCallback = (data) => {
    this.setState({ changePasswordModal: data });
    if(!data){
      this.setState({passWord:''});
      document.getElementById('passWord').value='';
      store.dispatch({type:"LOGIN_FAILED_CHANGE_AFTER_RESET",data:{}})
      store.dispatch({type:"LOGIN_FAILED_PASSWORD_EXPIRED",data:{}})
    }
};

  captchaExpireCallBack = () => {
    this.setState({captchaToken:'',captchaRequired:true});
  }

  render() {

    let errorDiv = null;
    if (this.state.errorMsg.length > 0)
      errorDiv = (<div className="errorMsg">{this.state.errorMsg}</div>);
    else if (this.state.loginFail)
      errorDiv = (<div className="errorMsg">{INCORRECT_MESSAGE}</div>);
    else if (this.state.unauth)
      errorDiv = (<div className="errorMsg">{UNAUTHORIZED_MESSAGE}</div>);
    else if (this.state.inactiveVendor)
      errorDiv = (<div className="errorMsg">{INACTIVEVENDOR_MESSAGE}</div>);
    else if (this.state.serviceFailed)
      errorDiv = (<div className="errorMsg">{NETWORK_ERROR}</div>);
 
    if(this.state.captchaRequired)
      errorDiv = (<div className="errorMsg">{CAPTCHA_VERIFICATION_REQUIRED_MESSAGE}</div>);
    else if (this.state.captchaFailed)
      errorDiv = (<div className="errorMsg">{CAPTCHAFAILED_MESSAGE}</div>);
  
    return (
      <div className="background-img" >
        <Modal show={this.state.changePasswordModal}>
          <Modal.Body>
            <Row xs={12}>
              <Col xs={12}>
               <ChangePassModalComponent error={this.state.passwordExpired ? "Password_Expired" : this.state.changeAfterReset ? "Change_After_Reset" : ""} onModalCallback={this.handleCallback}></ChangePassModalComponent>
              </Col>
            </Row>
          </Modal.Body>
        </Modal >

        <div className="loginBody">
          {this.state.showAlertMessage && <div className='alertbox'>
            <Row style={{textAlign:'center', width:'100%'}}><Col xs={12}><WarningOutlined /> Dear Supplier, On 29th Jan 2024 your current login password will expire and you will be prompted to change the same. Please ignore if already done. Thank You. </Col></Row>
          </div>}
          <div style={{display:"inline-block"}}><div className="sysco_logo"><img className="head_img" src={favicon} alt="sysco" /><div style={{ float: "left" }}></div></div>
            <div className="spanT">SMS Supplier Payables </div></div>
          {/* <div className="container"> */}

            <div className="loginCard">
              {errorDiv}
              <div className="inputsSection">
                <Form id="loginForm">
                  <FormGroup>
                    <InputGroup className="greenMargin">
                      <InputGroup.Addon className="inputImage"><span className="glyphicon glyphicon-user whiteIcon"></span></InputGroup.Addon>
                      <FormControl id="userId" className="username" type="text" placeholder="Username" style={{ width: "100% !important" }} />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="greenMargin">
                      <InputGroup.Addon className="inputImage"><span className="glyphicon glyphicon-lock whiteIcon"></span></InputGroup.Addon>
                      <FormControl id="passWord" className="" type="password" placeholder="Password" style={{ width: "100%" }} />
                    </InputGroup>
                  </FormGroup>
                  <div>
                  {this.state.captchaKey!="" ? (
                  <ReCAPTCHA ref={recaptchaRef} sitekey={this.state.captchaKey} onChange={this.captchaCallBack} onExpired={this.captchaExpireCallBack}/>
                  ): (
                    <p>Captcha loading is faield.!</p>
                  )
                  }
                  </div>
                  <div >
                  <Button style={{width:"100%",background: "#50b737",border: "#50b737"}} icon={<LoginOutlined />} loading={this.state.loading} type="primary" onClick={this.executeReCaptcha}>
                         LOGIN
                  </Button>
                    {/* <Button id="loginButton" className="loginButton" onClick={this.loginAction}>LOGIN</Button> */}
                    {/*<Button id="backButton" className="loginButton" onClick={this.backAction}>LOGIN</Button>*/}
                  </div>
                </Form>
              </div>
            </div>
            <div className="loginMsg" >
              <div style={{backgroundColor: "rgb(12, 105, 160)", marginBottom: "5px",marginTop: "3px"}}>
              <div style={{marginLeft:"10px"}}>Quick links</div> 
              </div>
              <div style={{display:"flex"}}>
              <div style={{marginLeft:"10px", display:"flex", alignItems:"center"}}><PlayCircleTwoTone /><a href="https://view.vzaar.com/21463864/player" target="_blank" rel="noopener noreferrer" style={{padding: "4px",color:"black"}}>Supplier Payables training video</a></div>
              {/* no longer existing 
              <div style={{marginLeft:"10px", display:"flex", alignItems:"center"}}><TagsTwoTone /><a href="https://bsccsupplierpayables-old.sysco.com" target="_blank" rel="noopener noreferrer" style={{padding: "4px",color:"black"}}>Old Supplier Payables Website</a></div> 
              */}
              </div>
              <div style={{backgroundColor: "rgb(81, 186, 56)", marginBottom: "5px",marginTop: "10px"}}>
              <div style={{marginLeft:"10px"}}>Message from SYSCO ({(this.props.dashboardReducer.loginMsg &&this.props.dashboardReducer.loginMsg.lastModifiedDate)?moment(this.props.dashboardReducer.loginMsg.lastModifiedDate).format("MM/DD/YYYY"):""})</div> 
              </div>
              <div style={{marginLeft:"10px",color: "black",fontSize:"12px"}}>{this.props.dashboardReducer.loginMsg?unescape(this.props.dashboardReducer.loginMsg.loginMessage):''}</div>
            </div>
            {/* <div className="loginMsg">
              <div style={{backgroundColor: "rgb(13, 138, 213)", marginBottom: "5px"}}>
              <div style={{marginLeft:"10px"}}>Message from SYSCO ({(this.props.dashboardReducer.loginMsg &&this.props.dashboardReducer.loginMsg.lastModifiedDate)?moment(this.props.dashboardReducer.loginMsg.lastModifiedDate).format("MM/DD/YYYY"):""})</div> 
              </div>
              <div style={{marginLeft:"10px"}}>{this.props.dashboardReducer.loginMsg?unescape(this.props.dashboardReducer.loginMsg.loginMessage):''}</div>
            </div><br/>
            <div className="loginMsg">
              <div style={{backgroundColor: "rgb(13, 138, 213)", marginBottom: "5px"}}>
              <div style={{marginLeft:"10px"}}>Training video</div> 
              </div>
              <div style={{marginLeft:"10px"}}><Icon type="play-circle" theme="filled"/><a ahref="www.google.com"></a></div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dashboardReducer: state.DashboardReducer,
    ctr:state.loginReducer
  };
}
const mapDispatchToProps= dispatch =>{
  return{
      OnLogin:(username,password,captchaToken,captchaBypassKey)=>{ dispatch(auth(username,password,captchaToken,captchaBypassKey));} 
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);