import React from "react";
import { Route, Redirect } from "react-router-dom";

class ProtectedRoute extends React.Component{
    render() {
        const { component: Component, isAuthenticated, ...rest } = this.props;
    
        return (
          <Route
            {...rest}
            render={(props) =>
              isAuthenticated ? (
                <Component {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
        );
      }
}

export default ProtectedRoute;